import React from 'react';
import PropTypes from 'prop-types';

import { isAndroid } from 'src/core/util/browser';

import Url from 'src/components/url/Url';

import 'src/components/generic-icon/GenericIcon.scss';
import './DetailSocialButton.scss';

const classname = {
  tw: 'twitter-icon',
  fb: 'facebook-icon',
  ig: 'instagram-icon',
  ln: 'linkedin-icon',
  yt: 'youtube-icon',
  tk: 'tiktok-icon',
};

function DetailSocialButton({ data, actions, labels }) {
  if (!data) {
    return null;
  }

  const target = global.isCordovaContext && !isAndroid() ? '_system' : null;
  const styleWithIcon = { WebkitMaskSize: '100%', maskSise: '100%' };

  const style = { };

  for (const [key, value] of Object.entries(data)) {
    const isString = value && typeof value === 'string';
    const link = isString ? value : value?.url;
    return (
      <Url
        className={isString || value?.withicon ? classname[key] : 'withoutIcon custom-colored-link'}
        callback={actions.linkClicked}
        href={link}
        openInInAppBrowser={value?.openInInAppBrowser}
        target={target}
        style={isString || value?.withicon ? styleWithIcon : style}
      >
        {!isString && !value?.withicon && labels?.social?.titleBtn}
      </Url>
    );
  }
}

DetailSocialButton.propTypes = {
  data: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

export default DetailSocialButton;
