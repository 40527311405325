import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  DATA_TYPE_EVENTS,
  DATA_TYPE_SPEAKERS,
  DATA_TYPE_PAPERS,
  DATA_TYPE_PAPER_CATEGORIES,
} from 'app-customs/config/dataConfig';

import { EVENT_PAGE_SPEAKERS_AS_ACCORDION } from 'app-customs/config/ficheConfig';

import { EVENT_PAGE_KEY, LIST_PAGE_KEY, PAPER_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailEvents from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailDocument from 'src/components/detail-document/DetailDocument';
import ShareButton from 'src/components/share-button/ShareButton';
import LinkButton from 'src/components/link-button/LinkButton';
import NoteButton from 'src/components/notes/NoteButton';
import List from 'src/components/list/List';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import '../common-fiche.scss';
import './PaperPage.scss';

class PaperContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(this.props.item.id, DATA_TYPE_PAPERS, this.props.isFavorite);
  };

  showEvents = () => {
    this.props.actions.navigateToItems(
      this.props.item.references[DATA_TYPE_EVENTS],
      DATA_TYPE_EVENTS,
      this.props.item.id,
      DATA_TYPE_PAPERS
    );
  };

  onClickOnCategory = (event) => {
    let paperCatId = parseInt(event.target.dataset.catId, 10),
      paperCat;

    // Get the exhibitor category
    let paperCats = this.props.item.categories;
    for (let i = 0; i < paperCats.length && !paperCat; i++) {
      if (paperCats[i].id === paperCatId) {
        paperCat = paperCats[i];
      }
    }
    if (!paperCat) {
      // Should never happen
      return;
    }

    let paperCatLump = paperCat.lump;

    if (Array.isArray(paperCatLump.papers) && paperCatLump.papers.length > 0) {
      // Display content
      this.props.actions.navigate(LIST_PAGE_KEY, {
        inputs: [
          {
            parentId: paperCatId,
            parentType: DATA_TYPE_PAPER_CATEGORIES,
            dataType: DATA_TYPE_PAPERS,
          },
        ],
      });
    } else {
      // Display sub-categories
      this.props.actions.navigate(LIST_PAGE_KEY, {
        inputs: [
          {
            id: paperCat.id,
            dataType: DATA_TYPE_PAPER_CATEGORIES,
          },
        ],
      });
    }
  };

  renderSpeakers = ({ items, label }) =>
    !Array.isArray(items) || items.length === 0 ? null : EVENT_PAGE_SPEAKERS_AS_ACCORDION ? (
      <DetailSpeakers
        speakers={items}
        label={label}
        actions={this.props.actions}
        labels={this.props.labels}
        pageKey={EVENT_PAGE_KEY}
      />
    ) : (
      <div>
        <div className="fiche-separator">{label}</div>
        <List
          items={items}
          dataType={DATA_TYPE_SPEAKERS}
          actions={this.props.actions}
          labels={this.props.labels}
          isFastAndUgly={false}
          displayFavorites={false}
          pageKey={EVENT_PAGE_KEY}
        />
      </div>
    );

  /**
   * All fields are not always exported in data json file,
   * so let's check field presence, then its value
   * @param {number} fieldIndex
   */
  dangerouslySetHtml(fieldIndex) {
    if (typeof fieldIndex === 'number' && this.props.item[fieldIndex]) {
      return <div dangerouslySetInnerHTML={{ __html: this.props.item[fieldIndex] }} />;
    }
    return null;
  }

  render() {
    const title = this.props.item.title,
      description = this.props.item.description,
      lump = this.props.item.lump,
      code = this.props.item.lump.code || title;

    return (
      <div className="fiche paper content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={code}
          dataType={DATA_TYPE_PAPERS}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={code}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_PAPERS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />

          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={PAPER_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            {/* events */}
            <DetailEvents
              items={this.props.item.references.events}
              labels={this.props.labels}
              onClick={this.showEvents}
            />
            {this.props.item.lump.code && (
              <div className="paper-title">
                <h2>{title}</h2>
                <span className="paper-authors">{this.props.labels.paper.authors}</span>
              </div>
            )}

            {/* simple speakers */}
            {this.renderSpeakers({
              items: this.props.item.references.speakers,
              label: this.props.labels.data.speakers.title,
            })}

            <br />
            <div className="paper-text">
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>

            {/* Counters */}
            <div>
              {/* paper link button */}
              {lump.document && (
                <DetailDocument
                  labels={this.props.labels}
                  actions={this.props.actions}
                  document={lump.document}
                />
              )}
            </div>

            {lump.button && <LinkButton config={lump.button} actions={this.props.actions} />}

            {/* categories */}
            {this.props.item.categories && this.props.item.categories.length > 0 && (
              <div>
                <div className="fiche-separator">{this.props.labels.paper.categories}</div>
                <div className="activity free-row">
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="fiche-contact-icon">
                        <GenericIcon className="tag-icon fiche-icon" />
                      </div>
                    </div>
                    <div className="prop-right cats flex-ellipsis">
                      {this.props.item.categories.map((paperCat) => (
                        <div
                          key={paperCat.id}
                          data-cat-id={paperCat.id}
                          onClick={this.onClickOnCategory}
                          className="activities-content colored-link"
                        >
                          <span>{paperCat.title}</span>

                          <div className="prop-right-fleche">
                            <GenericIcon
                              st0Class="chevron-right-icon-st0"
                              src="icons-svg/control/chevron-droit-solid.svg"
                              className="chevron-right-icon fleche"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* end of .allInformations */}
        </div>
      </div>
    );
  }
}

PaperContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default PaperContent;
