module.exports = {
  "name": "jasfgg",
  "client": "Mobile spot",
  "description": "Mobile app for test",
  "validLangs": ["fr"],
  "version": {
    "mainVersion": "2.0.0",
    "buildVersion": {
      "iOS": "39",
      "android": "38",
      "web": "40"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosBuildTarget": "11.0",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 30,
    "androidSigning": {
      "keyAlias": "mobilespot-jasfgg",
      "keyStorePassword": "jee1Uu0Hieloh7bi"
    },
    "app": "app-react"
  },
  "undeliveredFolders": ["source", "exports"],
  "crypto": false,
  "web": {}
};