import { PMR_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import PmrContent from './PmrContent';

export default {
  key: PMR_PAGE_KEY,
  path: '/pmr',
  elId: DOM_ID,
  className: 'pmr-page',
  component: GenericItemPage,
  childComponent: PmrContent,
};
