// Conf
import config from 'app-customs/config/config';
// import { DISPLAYABLE_TYPES } from 'app-customs/config/mobigeoConfig';

import { DATA_TYPE_FAVORITE_POSITIONS } from 'app-customs/config/dataConfig';
import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';

import { get as getLabels } from 'src/core/Lang';
import { isActive } from 'src/core/navigation/Router';
import * as Query from 'src/core/query/Query';
import showConfirmModal from 'src/core/util/showConfirmModal';
import { convertDataTypeToMobigeoType } from 'src/core/data-and-assets/Db';

import events from 'src/core/events.js';
import { getDisclaimer } from 'src/core/config-json/ConfigJsonManager';

import {
  StoredManualLocationStatus,
  StoredDisclaimerValue,
} from 'src/pages/mobigeo/locationHelper';

import { getBindedActions } from 'src/store/bindedActions';

import {
  DATA_ASSETS_UPDATED,
  HAS_NAVIGATED,
  MOBIGEO_LOADED,
  // MOBIGEO_USER_LOCATED,
  // MOBIGEO_USER_UNLOCATED,
  MAP_ZOOM_ON_ZONE,
  NAVIGATE,
  NAVIGATE_BACK,
  SHOW_MOBIGEO_ITINERARY,
  DISPATCH_ITINERARY,
  SHOW_ONE_POI_ON_MOBIGEO_WITHOUT_NAVIGATION,
  TOGGLE_FAVORITE,
  TOGGLE_PMR_STATUS,
  TOGGLE_LOCATION_CAPABILITY_STATUS,
  REQUEST_LOCATION_CAPABILITIES,
  STOP_LOCATION,
} from 'src/store/actionTypes';

import {
  itineraryApiCalled,
  mobigeoReload,
  toggleLocationCapabilityStatus,
} from 'src/store/actions';
import { USER_POSITION, detectMapAssetUpdate, detectMapDataUpdate } from './mobigeoUtil';
import MobigeoContext from './MobigeoContext';

const LOG_PREF = '[mobigeoMiddleware] ';

let locationOk = false;
let permissionOk = false;
let bluetoothOk = false;

let locationAskedB = false;
let locationAskedL = false;
let locationAskedP = false;

let watchID;

function bluetoothDisabled(dispatch) {
  console.log('-------------------------------BLUETOOTH DISABLED');
  bluetoothOk = false;
  checkGeoloc(dispatch);
  if (locationAskedB) {
    locationAskedB = false;
    showConfirmModal({
      text: getLabels().mobigeo.bluetoothWarning,
      // no callback
    });
  }
}

function bluetoothEnabled(dispatch) {
  console.log('-------------------------------BLUETOOTH ENABLED');
  bluetoothOk = true;
  checkGeoloc(dispatch);
}

function locationDisabled(dispatch) {
  locationOk = false;
  checkGeoloc(dispatch);
  console.log('-------------------------------LOCATION DISABLED');
  if (locationAskedL) {
    locationAskedL = false;
    showConfirmModal({
      text: getLabels().mobigeo.locationSettingWarning,
      yesBtnLabel: getLabels().common.ok,
      // no callback
    });
  }
}

function locationEnabled(dispatch) {
  locationOk = true;
  console.log('-------------------------------LOCATION ENABLED');
  checkGeoloc(dispatch);
}

function permissionDenied(dispatch) {
  permissionOk = false;
  checkGeoloc(dispatch);
  console.log('-------------------------------PERMISSION DENIED WITHOUT PROMPT');
  if (locationAskedP) {
    locationAskedP = false;
    window.navigator.geolocation.goSettings('app', {
      labels: {
        questionTitle: getLabels().mobigeo.goSettingsQuestionTitle,
        question: getLabels().mobigeo.goSettingsQuestion,
        yes: getLabels().common.yes,
        no: getLabels().common.no,
      },
    });
  }
}

function permissionDeniedWithPrompt(dispatch) {
  permissionOk = false;
  checkGeoloc(dispatch);
  console.log('-------------------------------PERMISSION DENIED WITH PROMPT');
}

function permissionGranted(dispatch) {
  console.log('-------------------------------PERMISSION GRANTED');
  permissionOk = true;
  checkGeoloc(dispatch);
}

function authorisationStatusChanged(dispatch) {
  console.log('-------------------------------AUTHORISATION STATUS CHANGED');
}

function checkGeoloc(dispatch) {
  console.log(
    'CHECK GEOLOC = ',
    locationOk && bluetoothOk && permissionOk,
    !StoredManualLocationStatus.hasBeenDisabled()
  );
  if (locationOk && bluetoothOk && permissionOk) {
    locationAskedB = locationAskedL = locationAskedP = false;
    if (!StoredManualLocationStatus.hasBeenDisabled())
      getBindedActions().toggleLocationCapabilityStatus(true);
  } else {
    getBindedActions().toggleLocationCapabilityStatus(false);
  }
}

let isDisclaimerDisplayed;

function checkDisclaimer(dispatch) {
  // console.log("Check disclaimer");
  if (!StoredDisclaimerValue.isAccepted()) {
    const disclaimer = getDisclaimer();
    if (!disclaimer || !disclaimer.text) {
      checkLocationAvailability(dispatch);
    } else if (isDisclaimerDisplayed !== true) {
      showConfirmModal({
        title: disclaimer.title,
        text: disclaimer.text,
        yesBtnLabel: getLabels().common.continue,
        /* noBtnLabel: getLabels().common.deny, */
        yesCb() {
          StoredDisclaimerValue.setAccepted();
          StoredManualLocationStatus.setEnabled();
          checkLocationAvailability(dispatch);
        },
        /* noCb() {
          StoredDisclaimerValue.setDenied();
          getBindedActions().stopLocation();
        },
        anywayCb() {
          isDisclaimerDisplayed = false;
        }, */
      });
    }
  } else {
    checkLocationAvailability(dispatch);
  }
}

function checkLocationAvailability(dispatch) {
  // console.log("checkLocationAvailability")
  // /
  const _authorisationStatusChanged = authorisationStatusChanged.bind(null, dispatch);
  const _permissionDenied = permissionDenied.bind(null, dispatch);
  const _permissionDeniedWithPrompt = permissionDeniedWithPrompt.bind(null, dispatch);
  const _permissionGranted = permissionGranted.bind(null, dispatch);
  const _locationDisabled = locationDisabled.bind(null, dispatch);
  const _locationEnabled = locationEnabled.bind(null, dispatch);
  const _bluetoothDisabled = bluetoothDisabled.bind(null, dispatch);
  const _bluetoothEnabled = bluetoothEnabled.bind(null, dispatch);
  // /
  events.unsubscribe(_authorisationStatusChanged);
  events.unsubscribe(_permissionDenied);
  events.unsubscribe(_permissionDeniedWithPrompt);
  events.unsubscribe(_permissionGranted);
  events.unsubscribe(_locationDisabled);
  events.unsubscribe(_locationEnabled);
  events.unsubscribe(_bluetoothDisabled);
  events.unsubscribe(_bluetoothEnabled);
  // /
  events.subscribe('geoloc.authorisationStatusChanged', _authorisationStatusChanged);
  events.subscribe('geoloc.permissionDenied', _permissionDenied);
  events.subscribe('geoloc.permissionDeniedWithPrompt', _permissionDeniedWithPrompt);
  events.subscribe('geoloc.permissionGranted', _permissionGranted);
  events.subscribe('geoloc.locationDisabled', _locationDisabled);
  events.subscribe('geoloc.locationEnabled', _locationEnabled);
  events.subscribe('geoloc.bluetoothDisabled', _bluetoothDisabled);
  events.subscribe('geoloc.bluetoothEnabled', _bluetoothEnabled);
  // /
  locationAskedB = locationAskedL = locationAskedP = true;
  // /
  watchID = window.navigator.geolocation.watchPosition(positionSuccess, positionError, {
    timeout: 30000,
  });
}

function _stopLocation() {
  console.log('stopLocation');
  StoredManualLocationStatus.setDisabled();
  locationAskedB = locationAskedL = locationAskedP = false;
  window.navigator.geolocation.clearWatch(watchID);
  locationOk = permissionOk = bluetoothOk = false;
}

function positionSuccess(position) {
  /* console.log("POSITION SUCCESS = ", position)
    console.log("positionSuccess")
    console.log('Latitude: ', position.coords.latitude, '\r',
        'Longitude: ', position.coords.longitude, '\r',
        '\r'); */
}

function positionError(error) {
  console.log(`code: ${error.code}\n` + `message: ${error.message}\n`);
}

// let hasUserAPosition = false;
const queuedActions = [];
let mapReloadConfirmDisplayed = false;
let reloadOnPageChange = false;

const isReady = () => MobigeoContext.isLoaded() && isActive(MOBIGEO_PAGE_KEY);

function _executeOrQueue(_func) {
  if (!isReady()) {
    // If Map page is not mounted yet, dispatch a navigate action to Map page
    // When MOBIGEO_LOADED is broadcasted, empty actions queue
    queuedActions.push(_func);
  } else {
    _func();
  }

  window.setTimeout(redirectToMapIfNeeded, 80);
}

function executeQueuedActions() {
  if (isReady()) {
    while (queuedActions.length) {
      queuedActions.pop()();
    }
  }
}

function redirectToMapIfNeeded() {
  // Redirect to map page only if not already active
  if (isActive(MOBIGEO_PAGE_KEY) !== true) {
    getBindedActions().navigate(MOBIGEO_PAGE_KEY);
  }
}

/**
 * Check every POI before displaying them
 * @param  {array} pois
 * @param  {object} options (optional)
 * @param  {function} dispatch
 */
function _parseThenShowPOIs(pois, options, dispatch) {
  const entries = [];
  Object.keys(pois).forEach((dataType) => {
    // Is datatype displayable on MobiGeo ?
    // if (DISPLAYABLE_TYPES.indexOf(dataType) !== -1) {
    if (Array.isArray(pois[dataType]) && pois[dataType].length > 0) {
      pois[dataType].forEach((poiCriteria) => {
        if (poiCriteria) {
          let member;

          // Favorite position
          if (dataType === DATA_TYPE_FAVORITE_POSITIONS) {
            member = window.MobiGeo.Favorite.getAll().find((fav) => fav.id === poiCriteria.id);
          } else if (typeof poiCriteria.id !== 'undefined') {
            member = Query.get(poiCriteria.id, dataType, ['places']);
          }
          // using client id (originalId) - case of push actions (e.g pushwoosh notification)
          else if (typeof poiCriteria.originalId !== 'undefined') {
            member = Query.find(
              [(item) => item.original_id === poiCriteria.originalId],
              dataType,
              { places: true }, // additional data to retrieve
              true
            ); // find one
          }
          if (member) {
            entries.push({
              id: dataType === DATA_TYPE_FAVORITE_POSITIONS ? member.id : member.original_id,
              type: dataType,
              placeId: poiCriteria.placeId, // (optional)
            });
          }
        }
      });
    }
    // }
  });

  if (entries.length > 0) {
    _showPOI(entries, options, dispatch);
  }
}

/**
 * Display a single POI
 * @param  {array} pois
 * @param  {object} options (optional)
 * @param  {function} dispatch
 */
function _showPOI(pois, options, dispatch) {
  _executeOrQueue(() => {
    console.log(`${LOG_PREF}Show POI(s)`, pois);

    window.MobiGeo.Map.POI.clear();

    const poisArray = (Array.isArray(pois) ? pois : [pois]).map((poi) => {
      if (poi.type.charCodeAt(0) > 96) {
        // if first letter is a lower case, data type must be converted
        poi.type = convertDataTypeToMobigeoType(poi.type);
      }
      return poi;
    });

    window.MobiGeo.Map.POI.show(poisArray, options);
  });
}

function showFavoritePosition(favorite, dispatch) {
  _executeOrQueue(() => {
    console.log(`${LOG_PREF}Show favorite position`, favorite);

    window.MobiGeo.Favorite.create(favorite, function(err, favorite) {
      if (err) {
        if (err === 'FAV_ERROR_ALREADY_EXISTS') {
          // Show POI
          _parseThenShowPOIs(
            {
              [DATA_TYPE_FAVORITE_POSITIONS]: [transformFavoriteToPOI(favorite)],
            },
            null,
            dispatch
          );
          return;
        }
        console.error(`${LOG_PREF}Failed to create a favorite position`, err);
      }
    });
  });
}

function setCustomPoiStyle(pois, dispatch) {
  _executeOrQueue(() => {
    //MobiGeo.Map.POI.resetState(); Function does not exist yet in mobigeo API
    MobiGeo.Map.POI.setState(pois, function(err) {
      if (err) {
        console.error(`${LOG_PREF}Failed to customize POI state`, pois, err);
      }
    });
    // Focus
    MobiGeo.Map.POI.show(pois, { noPins: true });
  });
}

/**
 * Focus on a zone
 * @param  {number} zone
 * @param  {string} floor
 * @param  {function} dispatch
 */
function _zoomOnZone(zone, floor) {
  _executeOrQueue(() => {
    // To avoid confusion, remove POI icons first
    window.MobiGeo.Map.POI.clear();

    window.MobiGeo.Map.zoomOnZone(zone, floor);
  });
}

function proceedToReloadAfterDataAssetsUpdate() {
  getBindedActions().showNotification({
    message: getLabels().mobigeo.reloadDueToUpdate,
    duration: 2, // sec
  });
  getBindedActions().mobigeoReload();
}

function _askConfirmIfNeededBeforeRestart() {
  if (isActive(MOBIGEO_PAGE_KEY) === false) {
    proceedToReloadAfterDataAssetsUpdate();

    // Skip confirmation
  } else if (config.MAP.MOBIGEO.AUTO_RELOAD_AT_UPDATE) {
    proceedToReloadAfterDataAssetsUpdate();

    // Ask confirmation to reload the map now
  } else if (!mapReloadConfirmDisplayed) {
    mapReloadConfirmDisplayed = true;

    showConfirmModal({
      title: getLabels().mobigeo.title,
      text: getLabels().mobigeo.shouldReload,
      anywayCb() {
        mapReloadConfirmDisplayed = false;
      },
      yesCb() {
        proceedToReloadAfterDataAssetsUpdate();
      },
      noCb() {
        reloadOnPageChange = true;
      },
    });
  }
}

let lastNavigateBackTimestamp;

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case HAS_NAVIGATED:
      if (action.pageKey !== MOBIGEO_PAGE_KEY) {
        break;
      } // else fall through
    case MOBIGEO_LOADED:
      window.setTimeout(executeQueuedActions, 50);
      break;

    case DATA_ASSETS_UPDATED:
      // Check if a reload is required
      if (MobigeoContext.isNotLoaded() !== true) {
        const updatedTables = detectMapDataUpdate(action.tables);
        const updatedAssets = detectMapAssetUpdate(action.assets);

        if (updatedTables.length > 0) {
          console.info(`${LOG_PREF}Map data update detected: `, updatedTables);
        }
        if (updatedAssets.length > 0) {
          console.info(`${LOG_PREF}Map assets update detected: `, updatedAssets);
        }
        if (updatedTables.length > 0 || updatedAssets.length > 0) {
          window.setTimeout(_askConfirmIfNeededBeforeRestart, 2000);
        }
      }
      break;

    case NAVIGATE:
      if (
        action.pageKey === MOBIGEO_PAGE_KEY &&
        action.options &&
        // Ignore if navigation comes from a 'back' (to not apply again POI options)
        (!lastNavigateBackTimestamp || new Date().getTime() - lastNavigateBackTimestamp > 300)
      ) {
        // Show one POI
        if (action.options.poi) {
          _parseThenShowPOIs(
            {
              [action.options.poi.type]: [action.options.poi],
            },
            null,
            dispatch
          );
        }
        // Show several POIs
        if (action.options.pois) {
          _parseThenShowPOIs(action.options.pois, null, dispatch);
        }

        // Show a favorite position
        if (action.options.favorite) {
          showFavoritePosition(action.options.favorite, dispatch);
        }

        // Set custom POI styles on map
        if (action.options.customPoiState) {
          setCustomPoiStyle(action.options.customPoiState, dispatch);
        }
      }

      // After an update involving map data/assets (see above DATA_ASSETS_UPDATED), if user is on MobigeoPage
      // then confirmation is asked for immediate map reload.
      //
      // If the user refuses:
      //  - `reloadOnPageChange` is set to true
      //  - on first page navigation, map is reloaded in background
      if (reloadOnPageChange) {
        reloadOnPageChange = false;
        dispatch(mobigeoReload());
      }
      break;

    case NAVIGATE_BACK:
      lastNavigateBackTimestamp = new Date().getTime();

      if (reloadOnPageChange) {
        reloadOnPageChange = false;
        dispatch(mobigeoReload());
      }
      break;

    // case MOBIGEO_USER_LOCATED:
    //     hasUserAPosition = true;
    //     break;

    // case MOBIGEO_USER_UNLOCATED:
    //     hasUserAPosition = false;
    //     break;

    case TOGGLE_PMR_STATUS:
      window.MobiGeo.Map.Route.setPMRStatus(action.value);
      break;

    case REQUEST_LOCATION_CAPABILITIES:
      console.log('REQUEST_LOCATION_CAPABILITIES MIDDLEWARE');
      checkDisclaimer(dispatch);
      break;

    case TOGGLE_LOCATION_CAPABILITY_STATUS:
      console.log('TOGGLE_LOCATION_CAPABILITY_STATUS MIDDLEWARE = ', action.value);
      if (action.value === false) {
        MobiGeo.Location.stop();
      } else if (action.value === true) {
        MobiGeo.Location.start();
      }
      break;

    case STOP_LOCATION:
      console.log('STOP LOCATION MIDDLEWARE');
      dispatch(toggleLocationCapabilityStatus(false));
      _stopLocation();
      break;

    case MAP_ZOOM_ON_ZONE:
      _zoomOnZone(action.zone, action.floor);
      break;

    case SHOW_MOBIGEO_ITINERARY:
      const _start = action.start;
      const _dest = action.dest;
      const _options = action.options;

      _dest.type = convertDataTypeToMobigeoType(_dest.type);

      if (_start.type === USER_POSITION) {
        // User position to POI
        window.MobiGeo.Map.Route.goTo(_dest, _options); // no callback, handle error events in MobigeoPage
      } else {
        // POI to POI
        _start.type = convertDataTypeToMobigeoType(_start.type);
        window.MobiGeo.Map.Route.display(_start, _dest, _options); // no callback, handle error events in MobigeoPage
      }

      dispatch(itineraryApiCalled(_start, _dest));
      break;

    case DISPATCH_ITINERARY:
      const _poi = action.poi;
      window.MobiGeo.Map.Route.dispatch(_poi);
      break;

    case SHOW_ONE_POI_ON_MOBIGEO_WITHOUT_NAVIGATION:
      action.options.poi &&
        _parseThenShowPOIs(
          {
            [action.options.poi.type]: [action.options.poi],
          },
          null,
          dispatch
        );
      break;

    case TOGGLE_FAVORITE:
      // User unchecked the favorite icon of a position on favorite page
      if (action.dataType === DATA_TYPE_FAVORITE_POSITIONS && action.source !== MOBIGEO_PAGE_KEY) {
        if (action.isFav) {
          window.MobiGeo.Favorite.remove(parseInt(action.id, 10));
        } else {
          // Create/show
          showFavoritePosition(action.data, dispatch);
        }
      }
      break;

    default:
  }
  return result;
};

function transformFavoriteToPOI(fav) {
  return {
    id: fav.id,
    type: DATA_TYPE_FAVORITE_POSITIONS,
  };
}
