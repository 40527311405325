import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'src/components/loader/Loader';
// import Url from 'src/components/url/Url';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import { isIOS } from 'src/core/util/browser';

import './PmrPage.css';

class PmrContent extends React.PureComponent {
  render() {
    return (
      <div className="pmr-page-content-container content-font content-below-apptoolbar">
        <div className="pmr-container">
          <div dangerouslySetInnerHTML={{ __html: this.props.item.description }} />

          <a
            href={isIOS() ? this.props.item.ios_link : this.props.item.android_link}
            className="pmr-link"
          >
            <img role="presentation" src={getUrl(this.props.item.logo)} />
          </a>
        </div>
      </div>
    );
  }
}

PmrContent.propTypes = {
  shouldFetch: PropTypes.bool,
  data: PropTypes.object,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default PmrContent;
