import { ENV, BO_URL } from 'app-customs/config/config';

import {
  DATA_TYPE_AIRCRAFT_CATEGORIES,
  DATA_TYPE_ANIMATIONS,
  DATA_TYPE_EVENT_CATEGORIES,
  DATA_TYPE_EXHIBITOR_CATEGORIES,
  DATA_TYPE_STORE_CATEGORIES,
  DATA_TYPE_SERVICE_CATEGORIES,
  DATA_TYPE_AIRCRAFTS,
  DATA_TYPE_EVENTS,
  DATA_TYPE_EXHIBITORS,
  DATA_TYPE_STORES,
  DATA_TYPE_SERVICES,
  DATA_TYPE_SPEAKERS,
  DATA_TYPE_SPEAKER_CATEGORIES,
  DATA_TYPE_NEWPRODUCTS,
  DATA_TYPE_NEWPRODUCT_CATEGORIES,
} from 'app-customs/config/dataConfig';

import {
  CHOOSE_PROFILE_PAGE_KEY,
  HOME_PAGE_KEY,
  LIST_PAGE_KEY,
  LIST_GROUPS_PAGE_KEY,
  INBOX_PAGE_KEY,
  MEDIAS_PAGE_KEY,
  SERVICE_PAGE_KEY,
} from 'src/pages/pagesKeys';

import fetchHelper from 'src/core/util/FetchHelper';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { getCurrent as getCurrentLang } from 'src/core/Lang';
import { isIOS, isAndroid } from 'src/core/util/browser';
import { simpleSort } from 'src/core/util/sortUtil';
import { getBindedActions } from 'src/store/bindedActions';

const LOG_PREF = '[ConfigJsonManager] ';

export const CONFIG_JSON_PATH = '/files/project/config.json';

let configJson;

export const init = () => {
  if (!BO_URL) {
    // There is no cake backend for this project, so no config.json to load
    getBindedActions().configJsonLoaded({});
    return;
  }
  // iniatialisation of LOCAL_STORAGE_KEY to initiate the counter of displaying ads //https://mobile-spot.atlassian.net/browse/MOM-108
  AdDisplayCounter.init();


  const configJsonUrl = getUrl(CONFIG_JSON_PATH);
  // console.log(LOG_PREF+'About to load '+configJsonUrl);

  fetchHelper(
    configJsonUrl,
    null,
    true,
    function fetchconfigJsonSuccess(data) {
      console.log(`${LOG_PREF}config.json loaded`, data);
      configJson = data;
      getBindedActions().configJsonLoaded(configJson);
    },
    function fetchconfigJsonFailure(err) {
      console.error(`${LOG_PREF}Failed to get config.json`, err);
      // Dispatch action even if request has failed to allow boot sequence to start
      // @see store/reducers/bootMiddleware
      getBindedActions().configJsonLoaded(configJson);
    },
    // Do not display a modal on error
    false
  );
};

if (ENV === 'dev') {
  global.initConfigJson = init;
}

export const getAdConfig = (profile) =>
  configJson && configJson.ads ? configJson.ads[profile] : null;

export const isSocialMediaBasicDisplay = () =>
  configJson && configJson.socialmedia && configJson.socialmedia.basicDisplay === true;

export const getSocialMediaConfig = (profile) =>
  configJson && configJson.socialmedia ? configJson.socialmedia[profile] : null;

export const getProfileCodes = () => (configJson ? configJson.profilecodes : null);

export function getDisclaimer() {
  if (configJson && configJson.disclaimers) {
    let osKey;
    if (isIOS()) {
      osKey = 'ios';
    } else if (isAndroid()) {
      osKey = 'android';
    }
    if (osKey && configJson.disclaimers[osKey]) {
      return configJson.disclaimers[osKey][getCurrentLang()];
    }
  }
}

export const AD_BUNDLE_ATTRIBUTION_KEYS = {
  HOME: 'home',
  HOME_HEADER: 'header',
  AIRCRAFTS: 'aircrafts',
  EVENTS: 'events',
  SERVICES: 'services',
  EXHIBITORS: 'exhibitors',
  ANIMATIONS: 'animations',
  SPEAKERS: 'speakers',
  NEWPRODUCTS: 'newproducts',
  INBOX: 'inbox',
  MEDIAS: 'medias',
  STORES: 'stores',
};

/**
 * NB: Can be overriden by AdBanner `forcedAdKey` prop
 * @param {string} pageKey
 * @param {array}  listInputs
 */

const getAdBundleAttributionForDataType = (dataType) => {
  if (dataType === DATA_TYPE_AIRCRAFT_CATEGORIES || dataType === DATA_TYPE_AIRCRAFTS) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.AIRCRAFTS;
  }
  if (dataType === DATA_TYPE_EVENT_CATEGORIES || dataType === DATA_TYPE_EVENTS) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.EVENTS;
  }
  if (dataType === DATA_TYPE_EXHIBITOR_CATEGORIES || dataType === DATA_TYPE_EXHIBITORS) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.EXHIBITORS;
  }
  if (dataType === DATA_TYPE_STORE_CATEGORIES || dataType === DATA_TYPE_STORES) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.STORES;
  }
  if (dataType === DATA_TYPE_SERVICE_CATEGORIES || dataType === DATA_TYPE_SERVICES) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.SERVICES;
  }
  if (dataType === DATA_TYPE_ANIMATIONS) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.ANIMATIONS;
  }
  if (dataType === DATA_TYPE_SPEAKER_CATEGORIES || dataType === DATA_TYPE_SPEAKERS) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.SPEAKERS;
  }
  if (dataType === DATA_TYPE_NEWPRODUCT_CATEGORIES || dataType === DATA_TYPE_NEWPRODUCTS) {
    return AD_BUNDLE_ATTRIBUTION_KEYS.NEWPRODUCTS;
  }
  // No ad for other types
  return null;
};

export const getAdBundleAttributionKeyForPage = (pageKey, listInputs, dataType) => {
  switch (pageKey) {
    case CHOOSE_PROFILE_PAGE_KEY:
      return AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER;

    case HOME_PAGE_KEY:
      return AD_BUNDLE_ATTRIBUTION_KEYS.HOME;

    case MEDIAS_PAGE_KEY:
      return AD_BUNDLE_ATTRIBUTION_KEYS.MEDIAS;

    case INBOX_PAGE_KEY:
      return AD_BUNDLE_ATTRIBUTION_KEYS.INBOX;
    case SERVICE_PAGE_KEY:
    case LIST_PAGE_KEY:
    case LIST_GROUPS_PAGE_KEY:
      if (dataType) {
        return getAdBundleAttributionForDataType(dataType);
      }
      if (!listInputs || listInputs.length === 0) {
        console.error(
          `${LOG_PREF}Could not determine ad for page:`,
          pageKey,
          ' as list inputs are missing:',
          listInputs
        );
        return null;
      }
      if (listInputs.length === 1) {
        /* if (typeof listInputs[0].id !== 'undefined') {
                    // No ad on a subcategory content
                    return null;
                }
                else */
        return getAdBundleAttributionForDataType(listInputs[0].dataType);
      }
      if (listInputs.length === 2) {
        if (typeof listInputs[0].id !== 'undefined' || typeof listInputs[1].id !== 'undefined') {
          // No ad on a subcategory content
          return null;
        }
        if (
          listInputs[0].dataType === DATA_TYPE_EXHIBITOR_CATEGORIES ||
          listInputs[1].dataType === DATA_TYPE_EXHIBITOR_CATEGORIES
        ) {
          return AD_BUNDLE_ATTRIBUTION_KEYS.EXHIBITORS;
        }
        else if (
          listInputs[0].dataType === DATA_TYPE_STORE_CATEGORIES ||
          listInputs[1].dataType === DATA_TYPE_STORE_CATEGORIES
        ) {
          return AD_BUNDLE_ATTRIBUTION_KEYS.STORES;
        }
        // No ad for other types
        return null;
      }
      break;

    default:
      console.error(`${LOG_PREF}Unhandled page ${pageKey}`);
  }
  console.error(
    `${LOG_PREF}Could not determine ad for page:`,
    pageKey,
    ', list inputs:',
    listInputs
  );
  return null;
};

/**
 * e.g available width is 314px because of 3px horizontal margins,
 * if an ad width is 320px, it will be selected as 320 <= 314+AD_ALLOWED_OVERFLOW
 * @type {Number}
 */
const AD_ALLOWED_OVERFLOW = 6;

/**
 * Copied/pasted from old cake framework, adapted what needed to be adapted
 * @param  {object} files
 * @param  {number} width
 * @param  {number} height
 * @return {string}
 */
export const getBestRessource = (files, width, height) => {
  if (!files || typeof files !== 'object') {
    console.error(`${LOG_PREF}Cannot determine best resource from files argument:`, files);
    return;
  }

  const checkHeight = true;
  let orientation;
  console.log(files);
  const orientations = Array.isArray(files) ? files : Object.keys(files);

  switch (orientations.length) {
    case 0:
      console.error(`${LOG_PREF}Cannot determine ad because no file is defined`, files);
      return;

    case 1:
      orientation = orientations[0];
      break;

    default:
      // > 1
      if (document.documentElement.clientWidth > document.documentElement.clientHeight) {
        orientation = 'l'; // landscape
      } else {
        orientation = 'p'; // portrait
      }
  }

  const _files = files[orientation];

  // get the closer format available
  let imageWidth = null;
  for (var i in _files) {
    if (_files.hasOwnProperty(i) === false) {
      continue;
    }
    i = parseInt(i, 10);

    if (imageWidth === null) {
      imageWidth = i;
    }

    if (i <= width + AD_ALLOWED_OVERFLOW) {
      imageWidth = i;
    }
  }

  let ads = _files[imageWidth];
  if (!ads) {
    console.error('No ads defined for this format', imageWidth);
    return;
  }

  let imageHeight = null;
  for (i in ads) {
    if (ads.hasOwnProperty(i) === false) {
      continue;
    }
    i = parseInt(i, 10);

    if (imageHeight === null) {
      imageHeight = i;
    }

    if (checkHeight === false) {
      if (i >= imageHeight) {
        imageHeight = i;
      }
    } else if (i <= height) {
      imageHeight = i;
    }
  }

  ads = _files[imageWidth][imageHeight];

  // get the best Device Pixel Ratio ads
  let adFile = null;
  for (i in ads) {
    if (ads.hasOwnProperty(i) === false) {
      continue;
    }
    i = parseInt(i, 10);

    if (adFile === null) {
      adFile = ads[i];
    }

    if (i === parseInt(window.devicePixelRatio, 10)) {
      adFile = ads[i];
    }
  }

  return {
    width: imageWidth,
    height: imageHeight,
    file: adFile,
  };
};

/**
 * Count how many times each ad has been displayed
 */
const AdDisplayCounter = (function() {
  const LOCAL_STORAGE_KEY = 'ad_display';

  let counter = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};

  // Persist current counter to localStorage
  function save() {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(counter));
  }

  function init() {
    const adsTmp = {};
    Object.keys(counter).map((ad) => {
      Object.assign(adsTmp, {[ad]: 1});
    });
    counter = adsTmp;
    save();
  }

  return {
    get: (adName) => counter[adName] || 0,
    increment: (adName) => {
      counter[adName] = (counter[adName] || 0) + 1;
      save();
    },
    init: () => init()
  };
})();

/**
 * Return the least seen ad from the bundle
 * @param  {object} bundle
 * @param  {object} ads
 */
export function getLeastSeenAdFromBundle(bundle, ads) {
  const sortByCount = bundle
    .map((adName) => ({ adName, count: AdDisplayCounter.get(adName) }))
    .sort((ad1, ad2) => simpleSort(ad1.count, ad2.count));

  const leastSeenAd = sortByCount[0].adName;

  AdDisplayCounter.increment(leastSeenAd);

  // console.info('LEAST SEEN AD IS: '+leastSeenAd);

  return ads[leastSeenAd];
}
