import React from 'react';
// import PropTypes from 'prop-types';

import config from 'app-customs/config/config';

import Url from 'src/components/url/Url';

import './MobilespotBar.scss';

const MobilespotBar = (props) => (
  <div className="mobilespot-bar">
    {/* /!\ flex-direction: row-reverse */}

    <Url href={config.MOBILESPOT_WWW} label={props.labels.promoMs} />

    {config.SHOW_TERMS_OF_USE_ON_HOME === true && (
      <Url
        className="terms-of-use"
        href={config.TOU_LINK[props.lang]}
        label={props.labels.common.termsOfUse}
      />
    )}
  </div>
);

/*
MobilespotBar.propTypes = {
    url: PropTypes.string.isRequired,
    showTermsOfUse: PropTypes.bool,
};
*/

export default MobilespotBar;
