import config from 'app-customs/config/config';
var LOG_PREF = '[inAppBrowser options] ';
/**
 * Returns something like:
 *
 * android: 'location=no&hideurlbar=yes&toolbarcolor=#fff&closebuttoncaption=&closebuttoncolor=#0083C8&hardwareback=yes',
 * ios: 'location=no&toolbarcolor=#fff&toolbarposition=top&closebuttoncaption=Fermer&navigationbuttoncolor=#0083C8&enableViewportScale=yes',
 *
 * @param  {string} platform: android|ios
 * @param  {object} labels
 * @return {string}
 */

export default function get(platform, labels) {
  var hasError = false;

  if (!platform) {
    hasError = true;
    console.error("".concat(LOG_PREF, "Missing 'platform' argument (android|ios)"));
  }

  if (!labels) {
    hasError = true;
    console.error("".concat(LOG_PREF, "Missing i18n labels"));
  }

  if (hasError) return;
  var OPTIONS = {
    android: {
      location: 'yes',
      hideurlbar: 'yes',
      toolbarcolor: '#EEEEEE',
      closebuttoncolor: '#0083C8',
      hardwareback: 'yes'
    },
    ios: {
      location: 'no',
      toolbarposition: 'bottom',
      closebuttoncaption: labels.common.close,
      toolbarcolor: '#EEEEEE',
      navigationbuttoncolor: '#0083C8',
      toolbartranslucent: 'yes',
      enableViewportScale: 'yes'
    }
  };
  var _options = OPTIONS[platform];
  return Object.keys(_options).map(function (optName) {
    return "".concat(optName, "=").concat(_options[optName] || '');
  }).join(',');
}
/**
 * For manual test purpose
 * e.g getInAppBrowserOptions('android', { common: { close: 'Fermer' }} )
 * e.g getInAppBrowserOptions('ios', { common: { close: 'Fermer' }} )
 */

if (config.ENV === 'dev') {
  global.getInAppBrowserOptions = get;
}