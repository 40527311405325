import config from 'app-customs/config/config';

import { DATA_TYPE_EVENTS } from 'app-customs/config/dataConfig';

import {
  CLEAR_SEARCH_RESULTS,
  CONFIG_JSON_LOADED,
  DATA_ASSETS_UPDATED,
  GROUPED_ITEMS_FETCHED,
  HAS_NAVIGATED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  SEARCH_PERFORMED,
  SET_SEARCH_FIELD_VISIBLE,
  SYNOPTIC_AGENDA_TAB_INDEX_UPDATE,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
  clearSearchResults,
  configJsonLoaded,
  dataUpdated,
  getPageCommonDefaultState,
  groupedItemsFetched,
  handleSetSearchFieldVisible,
  langChanged,
  profileChanged,
  searchPerformed,
  toggleLocationStatus,
  toggleMenu,
  togglePageAfterNavigation,
  updateKeyboardState,
  updateObject,
  updatePageState,
} from 'src/store/reducers/commons';

import { SYNOPTIC_AGENDA_PAGE_KEY } from 'src/pages/pagesKeys';
import { formatDayMonth } from 'src/core/Lang';

const USED_TABLES = [DATA_TYPE_EVENTS];

const DEFAULT_TAB_INDEX = 0;

const getDefaultState = () => ({
  ...getPageCommonDefaultState(SYNOPTIC_AGENDA_PAGE_KEY),
  isPending: true,
  index: 0,
  synoConfig: config.SYNOPTIC_AGENDA,
});

function ignoreEvent(event) {
  return !event.start_time || !event.end_time || !event.event_place_text;
}

const getTabLabel = (date) => formatDayMonth(date);

function _groupedItemsFetched(state, action) {
  if (action.dataType !== DATA_TYPE_EVENTS) {
    return state;
  }

  // Apply generic behaviour
  const newState = groupedItemsFetched(state, action);

  // Prevent modifying by reference the content displayed in agenda view
  const groupedItems = JSON.parse(JSON.stringify(newState.groupedItems));

  const eventsByDayByPlaces = {};

  // Then also group events by place ids
  Object.keys(groupedItems).forEach((day) => {
    const eventsByPlaces = {};

    // Filter events whose data is incomplete
    groupedItems[day].items = groupedItems[day].items.filter((event) => !ignoreEvent(event));

    // Iterate on events
    groupedItems[day].items.forEach((event) => {
      const placeKey = event.event_place_text;

      if (!eventsByPlaces[placeKey]) {
        eventsByPlaces[placeKey] = [];
      }
      eventsByPlaces[placeKey].push(event.id);
    });

    if (Object.keys(eventsByPlaces).length === 0) {
      delete groupedItems[day];
    } else {
      eventsByDayByPlaces[day] = eventsByPlaces;
    }
  });

  // Prepare data for tabs
  const tabsData = [];
  Object.keys(groupedItems).forEach((date, index) => {
    tabsData.push({
      key: date,
      label: getTabLabel(parseInt(date, 10)),
    });
  });

  newState.agendaProps = { input: action.input };
  newState.groupedItems = groupedItems;
  newState.eventsByDayByPlaces = eventsByDayByPlaces;
  newState.index = determineTabIndex(tabsData);
  newState.tabsData = tabsData;
  return newState;
}

function determineTabIndex(tabsData) {
  if (
    !config.SYNOPTIC_AGENDA.AUTOFOCUS_TAB ||
    Array.isArray(tabsData) !== true ||
    tabsData.length === 0
  ) {
    return DEFAULT_TAB_INDEX;
  }

  const currentDate = new Date(); // to test, mock this value, e.g new Date('2020-04-01 17:05:30')

  // Get the index of the tab matching the current day
  const todayLabel = getTabLabel(currentDate);
  const correspondingIndex = tabsData.findIndex((tabData) => tabData.label === todayLabel);
  if (correspondingIndex !== -1) {
    return correspondingIndex;
  }

  for (let i = 0; i < tabsData.length; i++) {
    const value = parseInt(tabsData[i].key);
    const comparingDate = new Date(value);
    if (currentDate < comparingDate) {
      return i;
    }
  }
  // Is current day after events displayed on synoptic?
  const latestEventDate = tabsData
    .map((tabData) => parseInt(tabData.key, 10))
    .sort()
    .reverse()[0];
  if (latestEventDate < currentDate) {
    return tabsData.length - 1;
  }

  // default case:
  return DEFAULT_TAB_INDEX;
}

const _synopticAgendaTabIndexUpdate = (state, action) =>
  updateObject(state, { index: action.index });

function _updatePageState(state, action) {
  const newState = updatePageState(state, action, SYNOPTIC_AGENDA_PAGE_KEY);

  if (
    action.pageKey === SYNOPTIC_AGENDA_PAGE_KEY &&
    JSON.stringify(action.props.agendaProps) !== JSON.stringify(state.agendaProps)
  ) {
    newState.groupedItems = null;
    newState.eventsByDayByPlaces = null;
  }
  return newState;
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CLEAR_SEARCH_RESULTS:
      return clearSearchResults(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case DATA_ASSETS_UPDATED:
      return dataUpdated(state, action, USED_TABLES);
    case GROUPED_ITEMS_FETCHED:
      return _groupedItemsFetched(state, action);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, SYNOPTIC_AGENDA_PAGE_KEY);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
    case SEARCH_PERFORMED:
      return searchPerformed(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
    case SET_SEARCH_FIELD_VISIBLE:
      return handleSetSearchFieldVisible(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
    case SYNOPTIC_AGENDA_TAB_INDEX_UPDATE:
      return _synopticAgendaTabIndexUpdate(state, action);
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return _updatePageState(state, action);

    default:
      return state;
  }
};
